/* @import '~mdb-ui-kit/css/mdb.min.css'; */

body {
    color: black;
    background-color: hsl(0, 0%, 97%);
}

.container {
    max-width: 1024px;
}

.btn-primary,
.bg-primary {
    background-color: rgb(7 89 133) !important;
}

.text-primary {
    color: rgb(7 89 133) !important;
}

.btn-danger,
.bg-danger {
    background-color: #b91c1c !important;
}

.text-danger {
    color: #b91c1c !important;
}

.btn-warning,
.bg-warning {
    background-color: rgb(161 98 7) !important;
}

.text-warning {
    color: rgb(161 98 7) !important;
}

.nav-pills.menu-sidebar .nav-link.active,
.nav-pills.menu-sidebar .show>.nav-link {
    color: rgb(7 89 133) !important;
    border-left: 0.125rem solid rgb(7 89 133) !important;
}

.background-image-height {
    height: 200px;
}

@media (max-width: 575.98px) {
    .background-image-height {
        height: 120px;
    }
}

.btn-to-top {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 99;
}